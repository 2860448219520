import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, NavigationExtras, ParamMap, Router } from '@angular/router';
import { Observable, startWith } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, shareReplay } from 'rxjs/operators';

@Injectable()
export class RouterFacade {
  private activeRoute: ActivatedRoute;
  readonly activeRoute$: Observable<ActivatedRoute>;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    activeRoute: ActivatedRoute,
  ) {
    this.activeRoute$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      // Force initial event as page initial load does not trigger NavigationEnd
      startWith({}),
      map(() => getLastChild(activeRoute)),
      shareReplay(1),
      takeUntilDestroyed(),
    );

    this.activeRoute = activeRoute;
    this.activeRoute$.subscribe({
      next: (activeRoute) => {
        this.activeRoute = activeRoute;
      },
    });
  }

  setRouteQueryParams(queryParams: Record<string, unknown>): void {
    this.router.navigate(/* ['.'] */ [], {
      relativeTo: this.activeRoute,
      replaceUrl: true,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  routeParamAsNumber$(paramMapKey: string | `:${string}`): Observable<number> {
    return this.routeParam$(paramMapKey).pipe(
      map((mapValue: string | null) => {
        if (mapValue === null) {
          throw new Error(`Route param "${mapValue}" is null`);
        }

        const mapNumberValue = parseInt(mapValue);
        if (isNaN(mapNumberValue)) {
          throw new Error(`Could not parse route parameter value "${mapValue}" as number`);
        }

        return mapNumberValue;
      }),
    );
  }

  routeParam$(paramMapKey: string | `:${string}`): Observable<string | null> {
    const mapKey = paramMapKey.includes(':') ? paramMapKey.split(':')[1] : paramMapKey;

    return this.activeRoute$.pipe(
      mergeMap((route) => route.paramMap),
      map((paramMap) => paramMap.get(mapKey)),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  routeQueryParams$(): Observable<ParamMap> {
    return this.activeRoute$.pipe(
      mergeMap((route) => route.queryParamMap),
      shareReplay(1),
    );
  }

  routeQueryParam$(queryParamMapKey: string): Observable<string | null> {
    return this.activeRoute$.pipe(
      mergeMap((route) => route.queryParamMap),
      map((queryParamMap) => queryParamMap.get(queryParamMapKey)),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  navigate(command: (router: Pick<Router, 'navigate'>, route: ActivatedRoute) => Promise<boolean>): Promise<boolean> {
    return command(this.router, this.activeRoute);
  }

  navigateBack(navigationExtras: NavigationExtras = {}): void {
    this.router.navigate(['../'], { relativeTo: this.activeRoute, ...navigationExtras });
  }

  back(): void {
    this.location.back();
  }
}

function getLastChild(route: ActivatedRoute): ActivatedRoute {
  let lastChild = route;
  while (lastChild.firstChild) {
    lastChild = lastChild.firstChild;
  }

  return lastChild;
}
